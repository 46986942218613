h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
  line-height: 1.2;
  color: inherit;
}

p {
  font-family: "Roboto", sans-serif;
}

.hero-container {
  background-image: url(../images/hero-image.jpg);
  background-color: black;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}
.trending-image {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.trending-char-container {
  -webkit-box-shadow: 7px 6px 14px -6px rgba(209, 209, 209, 0.69);
  -moz-box-shadow: 7px 6px 14px -6px rgba(209, 209, 209, 0.69);
  box-shadow: 7px 6px 14px -6px rgba(209, 209, 209, 0.69);
  background-color: #eefefe;
  border-radius: 5px;
}
.trending-char-container:hover {
  -webkit-box-shadow: 8px 7px 6px -7px rgba(209, 209, 209, 0.69);
  -moz-box-shadow: 8px 7px 6px -7px rgba(209, 209, 209, 0.69);
  box-shadow: 8px 7px 6px -7px rgba(209, 209, 209, 0.69);
  background-color: #ddfefd;
  border-radius: 5px;
  cursor: pointer;
}
.trending-char-title {
  font-weight: 700;
  font-size: 21px;
}
.trending-char-alignment {
  font-weight: 600;
  padding: 4px 8px 4px 8px;
  color: #fff;
}
.trending-char-power {
  font-size: 38px;
}
@media only screen and (max-width: 576px) {
  .trending-char-power {
    font-size: 21px;
  }
}
@media only screen and (max-width: 768px) {
  .trending-char-power {
    font-size: 28px;
  }
}
.search-results {
  position: absolute;
  width: 100%;
  background-color: white;
  border-color: black;
  border-width: 2px;
  z-index: 90;
  border-style: solid;
}
.remove-margin {
  margin: 0px !important;
}
.remove-padding {
  padding: 0px !important;
}
.form-container {
  background-color: #e4f4f7;
  border-radius: 5px;
}
.login-button-text {
  font-size: large;
}
.login-upper-header {
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
}
.login-upper-subtext {
  font-size: small;
  font-weight: 400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #f1f1f1 inset;
  -webkit-box-shadow: 0 0 0px 1000px #f1f1f1 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.header {
  background-color: #f1f1f1;
  height: 100px;
}
.nav-link {
  color: #111111;
}
.nav-link:hover {
  color: #808080;
}
.active .nav-link {
  color: #ffffff;
}
.footer {
  margin-top: 30px;
  padding: 25px 0px 10px 0px;
  background-color: #f1f1f1;
  color: #111111;
}
.footer a {
  color: #882411;
}
.hero-name {
  font-size: 32px;
  font-weight: 600;
}
.hero-rating {
  font-size: 32px;
  background-color: #bcd4e6;
  padding: 10px;
  border-style: solid;
  border-color: #cde5f7;
  border-radius: 100%;
}
.user-details-container {
  font-size: medium;
  margin-top: 36px;
}
.mobile-menu-text {
  color: #4d4dff;
  text-decoration: underline;
}
.mobile-menu-modal {
  width: 100% !important;
  margin: 0 !important;
}
.mobile-menu-modal .modal-content {
  height: 100vh;
  margin: 0 !important;
}
.advertisement {
  background-color: #ace5ee;
  min-height: 200px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .advertisement {
    margin-top: 20px;
  }
}
